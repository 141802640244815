/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

[hidden] {
    display: none !important;
  }

//   .ck-editor__editable_inline {
//     min-height: 300px !important;
// }

* {
  outline: none;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.mat-tab-body-content {
  padding-left: 0px;
  padding-right: 0px;
}

ckeditor {
  border: 1px solid #eee;
}